import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import merge from 'lodash.merge';
import { ToastSize, ToastTheme, useToasts } from '@soosap/sushi/Toast';

import { Basket } from 'server/src/app/order/types';
import { Variants } from 'server/src/app/carte/types';

import basketState from 'state/basket';
import offersState from 'state/offers';
import foodState from 'state/food';

import AddRemoveToast from './components/AddRemoveToast';
import generatePositionId from './utils/generatePositionId';

interface AddToBasketProps {
  offerId: string;
  delta?: number;
}

interface RemoveFromBasketProps {
  offerId: string;
  delta?: number;
}

interface UseBasketProps {
  basket: Basket;
  addToBasket: (props: AddToBasketProps) => void;
  removeFromBasket: (props: RemoveFromBasketProps) => void;
  positionExists: (offerId: string) => boolean;
}

const useBasket = (): UseBasketProps => {
  const { addToast } = useToasts();
  const [basket, setBasket] = useRecoilState<Basket>(basketState);
  const offers = useRecoilValue(offersState);
  const food = useRecoilValue(foodState);

  const addToBasket = ({ offerId, delta = 1 }: AddToBasketProps) => {
    const positionId = generatePositionId({
      offerId,
      offers,
    });
    const productId = positionId.split('--')[0];
    const offer = offers[productId];
    const position = basket[positionId];
    const foodItem = food[productId];

    let quantity = position?.quantity;

    // The position is not even in the basket and you are being asked to remove quantity from it.
    // Nothing to do here, return early.
    if (!position && delta <= 0) {
      return;
    }

    // The position is not yet in the basket and you are being asked to add a positive quantity to it
    if (!position && delta > 0) {
      const applicableVariants: Variants = Object.values(offer.variants || {})
        .filter(variant => variant.checked === true)
        .reduce((prev, curr) => {
          return {
            ...prev,
            [curr.id]: curr,
          };
        }, {});

      quantity = delta;
      const updatedBasket = merge({}, basket, {
        [positionId]: {
          id: positionId,
          productId,
          quantity,
          includedAt: Date.now(),
          variants: applicableVariants,
        },
      });

      addToast(
        <AddRemoveToast
          operation="add"
          variants={Object.values(applicableVariants)}
          foodItem={foodItem}
        />,
        {
          size: ToastSize.SMALL,
          theme: ToastTheme.LIGHT,
          autoDismiss: true,
        }
      );

      return setBasket(updatedBasket);
    }

    // The position is in the basket and applying the delta to the quantity does not wipe it out
    // So actually apply the delta change to the basket
    quantity = basket[positionId].quantity + delta;
    const updatedBasket = merge({}, basket, {
      [positionId]: merge({}, basket[positionId], {
        quantity,
      }),
    });

    addToast(
      <AddRemoveToast
        operation="add"
        variants={Object.values(basket[positionId].variants)}
        foodItem={foodItem}
      />,
      {
        size: ToastSize.SMALL,
        theme: ToastTheme.LIGHT,
        autoDismiss: true,
      }
    );

    return setBasket(updatedBasket);
  };

  const removeFromBasket = ({ offerId, delta = 1 }: RemoveFromBasketProps) => {
    const positionId = generatePositionId({
      offerId,
      offers,
    });

    const position = basket[positionId];
    const productId = positionId.split('--')[0];
    const foodItem = food[productId];

    // The position in not in the basket
    if (!position) return;

    // The position is in the basket and quantity is less than delta
    if (position.quantity < delta) return;

    // The position is in the basket and quantity is exactly equal to delta
    if (position.quantity === delta) {
      const updatedBasket = { ...basket };
      delete updatedBasket[positionId];
      setBasket(updatedBasket);
    }

    // The position is in the basket and quantity is above 1
    if (position.quantity > delta) {
      const quantity = basket[positionId].quantity - delta;
      const updatedBasket = merge({}, basket, {
        [positionId]: merge({}, basket[positionId], {
          quantity,
        }),
      });
      setBasket(updatedBasket);
    }

    addToast(
      <AddRemoveToast
        operation="remove"
        variants={Object.values(basket[positionId].variants)}
        foodItem={foodItem}
      />,
      {
        size: ToastSize.SMALL,
        theme: ToastTheme.LIGHT,
        autoDismiss: true,
      }
    );
  };

  const positionExists = (offerId: string) => {
    const positionId = generatePositionId({
      offerId,
      offers,
    });

    return basket[positionId] ? true : false;
  };

  return { basket, addToBasket, removeFromBasket, positionExists };
};

export default useBasket;
