import React from 'react';
import Icon, { IconTheme, IconSize } from '@soosap/sushi/Icon';

import PlusIcon from 'icons/Plus';
import MinusIcon from 'icons/Minus';

import styles from './AddRemoveToast.module.scss';
import { FoodItem, Variant } from 'server/src/app/carte/types';
import icons from 'icons/index';

export interface Props {
  operation: 'add' | 'remove';
  variants: Variant[];
  foodItem: FoodItem;
}

const AddRemoveToast: React.FC<Props> = ({ variants, operation, foodItem }) => {
  return (
    <div className={styles[`AddRemoveToast`]}>
      <span className={styles[`AddRemoveToast__icon`]}>
        <Icon
          theme={IconTheme.DARK}
          size={IconSize.MINI}
          svg={operation === 'add' ? PlusIcon : MinusIcon}
        />
      </span>
      <span className={styles[`AddRemoveToast__label`]}>{foodItem.title}</span>
      {variants.length > 0 && (
        <span className={styles[`AddRemoveToast__variants`]}>
          {variants.map((variant, index) =>
            variant.iconName ? (
              <Icon
                theme={IconTheme.DARK}
                key={variant.iconName}
                className={
                  variants.length !== index + 1
                    ? styles[`AddRemoveToast__variant`]
                    : ''
                }
                svg={icons[variant.iconName]}
              />
            ) : null
          )}
        </span>
      )}
    </div>
  );
};

export default AddRemoveToast;
