import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useSetRecoilState } from 'recoil';
import { graphql, useStaticQuery } from 'gatsby';

import {
  Carte,
  FoodItem,
  Offer,
  Bucket,
  Quote,
  Variant,
} from 'server/src/app/carte/types';
import offersState, { initialOffersState } from 'state/offers';
import foodState from 'state/food';

import Selection from './components/Selection';
import styles from './Order.module.scss';

const ORDER_QUERY = graphql`
  query OrderQuery {
    allFoodItem {
      nodes {
        id
        title
        description
        position
        vegetarian
      }
    }
    carte(id: { eq: "take-away" }) {
      id
      buckets {
        id
        offers {
          id
          active
          position
          quotes {
            id
            price
            position
          }
          variants {
            checked
            delta
            iconName
            id
            position
            title
            titleThermalPrinter
          }
        }
      }
    }
  }
`;

type OfferRaw = Offer & {
  quotes: Quote[];
  variants: Variant[];
};

type BucketRaw = Bucket & {
  offers: OfferRaw[];
};

type CarteRaw = Carte & {
  buckets: BucketRaw[];
};

interface Data {
  carte: CarteRaw;
  allFoodItem: {
    nodes: FoodItem[];
  };
}

export interface Props {
  className?: string;
}

const Order: React.FC<Props> = ({ className }) => {
  const {
    allFoodItem: { nodes: foodItems },
    carte: carteRaw,
  } = useStaticQuery<Data>(ORDER_QUERY);

  const setOffers = useSetRecoilState(offersState);
  const setInitialOffers = useSetRecoilState(initialOffersState);
  const setFood = useSetRecoilState(foodState);

  useEffect(() => {
    const food = foodItems.reduce((prev, curr) => {
      return { ...prev, [curr.id]: curr };
    }, {});
    setFood(food);

    const carte: Carte = {
      ...carteRaw,
      buckets: carteRaw.buckets.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.id]: {
            ...curr,
            offers: curr.offers.reduce(
              (prev, curr) => ({
                ...prev,
                [curr.id]: {
                  ...curr,
                  quotes: curr.quotes.reduce(
                    (prev, curr) => ({ ...prev, [curr.id]: curr }),
                    {}
                  ),
                  variants: curr.variants.reduce(
                    (prev, curr) => ({ ...prev, [curr.id]: curr }),
                    {}
                  ),
                },
              }),
              {}
            ),
          },
        }),
        {}
      ),
    };

    setOffers(carte.buckets['default'].offers);
    setInitialOffers(carte.buckets['default'].offers);
  }, []);

  return (
    <div className={clsx(styles['Order'], className)}>
      <Selection />
    </div>
  );
};

export default Order;
