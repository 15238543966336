import { Offers } from 'server/src/app/carte/types';

export interface Props {
  offerId: string;
  offers: Offers;
}

const generatePositionId = ({ offerId, offers }: Props) => {
  const offer = offers[offerId];

  let positionId = `${offer.id}`;
  if (offer.variants) {
    Object.values(offer.variants)
      .sort((a, b) => (a.position > b.position ? 1 : -1))
      .forEach(variant => {
        if (variant.checked) {
          positionId = `${positionId}--${variant.id}`;
        }
      });
  }

  return positionId;
};

export default generatePositionId;
