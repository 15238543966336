import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import merge from 'lodash.merge';
import Button, { ButtonSize } from '@soosap/sushi/Button';
import Checkbox, {
  getStateFromBoolean,
  CheckboxTheme,
} from '@soosap/sushi/Checkbox';
import Icon from '@soosap/sushi/Icon';

import useBasket from 'hooks/useBasket';
import Container from 'atoms/Container';
import Title from 'atoms/Title';
import offersState from 'state/offers';
import foodState from 'state/food';
import PlusIcon from 'icons/Plus';
import MinusIcon from 'icons/Minus';

import icons from 'icons/index';
import styles from './Selection.module.scss';

interface Props {}

const Selection: React.FC<Props> = () => {
  const { addToBasket, removeFromBasket, positionExists } = useBasket();
  const [offers, setOffers] = useRecoilState(offersState);
  const food = useRecoilValue(foodState);

  return (
    <>
      {/* <Title>Auswahl</Title> */}
      <Title>Take Away</Title>
      <Container.Inner className={styles[`Selection`]}>
        <ul className={styles[`Selection__list`]}>
          {Object.values(offers)
            .filter((offer) => offer.active === true)
            .sort((a, b) => (a.position > b.position ? 1 : -1))
            .map((offer) => {
              const foodItem = food[offer.id];

              const applicablePrice = offer.variants
                ? Object.values(offer.variants).reduce((prev, curr) => {
                    return prev + (curr.checked ? curr.delta : 0);
                  }, offer.quotes['1-x'].price)
                : offer.quotes['1-x'].price;

              return (
                <li key={offer.id} className={styles[`Selection__item`]}>
                  <div className={styles[`Selection__header`]}>
                    <div className={styles[`Selection__label`]}>
                      {foodItem.title}
                    </div>
                    <div className={styles[`Selection__cta`]}>
                      <Button.Group size={ButtonSize.SMALL}>
                        <Button
                          icon={MinusIcon}
                          size={ButtonSize.TINY}
                          disabled={!positionExists(offer.id)}
                          onClick={() => {
                            removeFromBasket({ offerId: offer.id, delta: 1 });
                          }}
                        />
                        <Button disabled className={styles[`Selection__price`]}>
                          {(applicablePrice / 100).toFixed(2).replace('.', ',')}
                          €
                        </Button>
                        <Button
                          icon={PlusIcon}
                          size={ButtonSize.TINY}
                          onClick={() => {
                            addToBasket({ offerId: offer.id, delta: 1 });
                          }}
                        />
                      </Button.Group>
                    </div>
                  </div>
                  <div className={styles[`Selection__body`]}>
                    {foodItem.description}
                  </div>
                  {offer.variants && (
                    <ul className={styles[`Selection__variants`]}>
                      {Object.values(offer.variants)
                        .sort((a, b) => a.position - b.position)
                        .map((variant) => {
                          const handleVariantUpdate = () => {
                            const updatedOffers = merge({}, offers, {
                              [offer.id]: merge({}, offer, {
                                variants: merge({}, offer.variants, {
                                  [variant.id]: merge({}, variant, {
                                    checked: !variant.checked,
                                  }),
                                }),
                              }),
                            });

                            setOffers(updatedOffers);
                          };

                          return (
                            <li
                              key={variant.id}
                              className={styles[`Selection__variant`]}
                              onClick={handleVariantUpdate}
                            >
                              <Checkbox
                                state={getStateFromBoolean(variant.checked)}
                                theme={CheckboxTheme.PRIMARY}
                              />
                              {variant.iconName && (
                                <Icon
                                  className={styles[`Selection__variant-icon`]}
                                  svg={icons[variant.iconName]}
                                />
                              )}
                              <span
                                className={styles[`Selection__variant-label`]}
                              >
                                {variant.title}
                              </span>
                              {variant.delta !== 0 && (
                                <span
                                  className={styles[`Selection__variant-delta`]}
                                >
                                  {`( ${(variant.delta / 100)
                                    .toFixed(1)
                                    .replace('.', ',')} EUR )`}
                                </span>
                              )}
                            </li>
                          );
                        })}
                    </ul>
                  )}
                </li>
              );
            })}
        </ul>
      </Container.Inner>
    </>
  );
};

export default Selection;
